<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.trusted-members') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.members') }}</router-link>
            </div>
          </div>

          <div class="subheader-right">
            <v-guard :permissions="['members-toolbar-save-search']">
              <button @click="$refs.memberlist.openSaveSearch()" class="btn btn-sm btn-light mr-3"><i
                  class="fas fa-save"></i> {{ $t('labels.save-search') }}
              </button>
            </v-guard>
            <v-guard :permissions="['members-toolbar-email']">
              <button @click="$refs.memberlist.openSendMail()" class="btn btn-sm btn-light mr-3">
                <i class="fas fa-envelope"></i> {{ $t('labels.send-email') }}
              </button>
            </v-guard>
            <v-guard :permissions="['members-toolbar-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                <b-dropdown-group id="dropdown-group-1" header="PDF">
                  <b-dropdown-item-button @click="$refs.memberlist.exportResults('members', 'pdf')">{{ $t('labels.general-information') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.memberlist.exportResults('address', 'pdf')">{{ $t('labels.address-labels') }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-group id="dropdown-group-2" header="Excel">
                  <b-dropdown-item-button @click="$refs.memberlist.exportMemberResultsEstimate('members')">{{ $t('labels.all-info') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.memberlist.exportMemberResultsEstimate('members_address_list')">{{ $t('labels.address-list') }}
                  </b-dropdown-item-button>
                  <!-- <b-dropdown-item-button @click="$refs.memberlist.exportMemberResultsEstimate('address')">Adressetiketter
                </b-dropdown-item-button> -->
                </b-dropdown-group>
              </b-dropdown>
            </v-guard>
            <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.wait') }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <member-list
            ref="memberlist"
            :facets="facets"
            :query="query"
            :fields="fields"
            :title="this.$t('labels.trusted-members')"
            @downloadStatus="toggleIsDownloading"
        ></member-list>
      </div>
    </div>
    <manual />
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import MemberList from '@/components/lists/member/MemberList';
import Manual from '@/components/Manual';

export default {
  components: {
    LayoutMain,
    MemberList,
    Manual
  },
  methods: {
    toggleIsDownloading(status) {
      this.isDownloading = status;
    },
  },
  data() {
    return {
      isDownloading: false,
      facets: [
        {
          level: 1,
          id: 'countySubFilter',
          nestedField: 'county',
          field: 'county.label.raw',
          label: this.$t('labels.county'),
          placeholder: this.$t('labels.county'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 1,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centres'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: null
        },
        {
          level: 1,
          id: 'localofficeFilter',
          field: 'localoffice.raw',
          label: this.$t('labels.local-office'),
          placeholder: this.$t('labels.local-office'),
          type: 'list',
          permission: null
        },
        {
          level: 1,
          id: 'categoryFilter',
          field: this.$field('category')+'.raw',
          label: this.$t('labels.category'),
          placeholder: this.$t('labels.category'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 1,
          id: 'nameFilter',
          field: 'full_name',
          label: this.$t('labels.name'),
          placeholder: `${this.$t('labels.name')}..`,
          type: 'search',
          permission: null
        },
        {
          level: 1,
          id: 'phoneFilter',
          field: ['phone_1', 'phone_2', 'mobile', 'mobile_office'],
          label: `${this.$t('labels.phone')} #`,
          placeholder: `${this.$t('labels.phone')} #..`,
          type: 'search',
          permission: null
        },
        {
          level: 1,
          id: 'idFilter',
          field: 'id_searchable',
          label: this.$t('labels.member-number'),
          placeholder: `${this.$t('labels.member')} #..`,
          type: 'search',
          permission: null
        },
        {
          level: 2,
          id: 'localBoardAssignmentSubFilter',
          field: this.$field('local_board_assignment')+'.raw',
          label: this.$t('labels.board-local'),
          placeholder: this.$t('labels.board-local'),
          type: 'list',
          permission: null
        },
        {
          level: 2,
          id: 'regionalBoardAssignmentSubFilter',
          field: this.$field('regional_board_assignment')+'.raw',
          label: this.$t('labels.board-region'),
          placeholder: this.$t('labels.board-region'),
          type: 'list',
          permission: null
        },
        {
          level: 2,
          id: 'nationalBoardAssignmentSubFilter',
          field: this.$field('national_board_assignment')+'.raw',
          label: this.$t('labels.board-union'),
          placeholder: this.$t('labels.board-union'),
          type: 'list',
          permission: null
        },
        {
          level: 2,
          id: 'isBoardMemberFilter',
          field: 'is_board_member.raw',
          label: this.$t('labels.board-members'),
          placeholder: this.$t('labels.board-members'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'isNominationCommitteeOrAuditorFilter',
          field: 'is_nomination_committee_or_auditor.raw',
          label: this.$t('labels.nomination-committee-auditors'),
          placeholder: this.$t('labels.nomination-committee-auditors'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'localNominationSubFilter',
          field: this.$field('local_nomination')+'.raw',
          label: this.$t('labels.nomination-committee-local'),
          placeholder: this.$t('labels.nomination-committee-local'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'regionalNominationSubFilter',
          field: this.$field('regional_nomination')+'.raw',
          label: this.$t('labels.nomination-committee-region'),
          placeholder: this.$t('labels.nomination-committee-region'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'nationalNominationSubFilter',
          field: this.$field('national_nomination')+'.raw',
          label: this.$t('labels.nomination-committee-union'),
          placeholder: this.$t('labels.nomination-committee-union'),
          type: 'list',
          small: true,
          permission: null
        },
                {
          level: 2,
          id: 'localRevisionSubFilter',
          field: this.$field('local_revision')+'.raw',
          label: this.$t('labels.auditors-local'),
          placeholder: this.$t('labels.auditors-local'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'regionalRevisionSubFilter',
          field: this.$field('regional_revision')+'.raw',
          label: this.$t('labels.auditors-region'),
          placeholder: this.$t('labels.auditors-region'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'nationalRevisionSubFilter',
          field: this.$field('national_revision')+'.raw',
          label: this.$t('labels.auditors-union'),
          placeholder: this.$t('labels.auditors-union'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'activeMinervaFilter',
          field: 'active_minerva.raw',
          label: this.$t('labels.users-minerva'),
          placeholder: this.$t('labels.users-minerva'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'volunteerSubFilter',
          field: this.$field('volunteer') + '.raw',
          label: this.$t('labels.supporter-status'),
          placeholder: this.$t('labels.supporter-status'),
          type: 'list',
          small: true,
          permission: null
        },
         {
          level: 2,
          id: 'isSupportFilter',
          field: 'is_support.raw',
          label: this.$t('labels.supporters'),
          placeholder: this.$t('labels.supporters'),
          type: 'list',
          permission: null,
        },
      ],
      query: {
        query: {
          bool: {
            should: [
              {
                match: {
                  local_nomination: 'Ja',
                },
              },
              {
                match: {
                  regional_nomination: 'Ja',
                },
              },
              {
                match: {
                  national_nomination: 'Ja',
                },
              },
              {
                match: {
                  local_revision: 'Ja',
                },
              },
              {
                match: {
                  national_revision: 'Ja',
                },
              },
              {
                match: {
                  regional_revision: 'Ja',
                },
              },
              {
                range: {
                  regional_member_board_assignments_id: {
                    gt: '0',
                  },
                },
              },
              {
                range: {
                  'national_member_board_assignments_id': {
                    gt: '0',
                  },
                },
              },
              {
                range: {
                  local_member_board_assignments_id: {
                    gt: '0',
                  },
                },
              },
            ],
            minimum_should_match: '1',
          },
        },
      },
      fields: [
        {
          key: 'id',
          sort_field: 'id',
          label: '#',
          width: 6,
        },
        {
          key: 'first_name',
          sort_field: 'first_name.raw',
          label: this.$t('labels.first-name'),
          width: 11,
        },
        {
          key: 'last_name',
          sort_field: 'last_name.raw',
          label: this.$t('labels.last-name'),
          width: 11,
        },
        {
          key: 'office',
          sort_field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          width: 12,
        },
        {
          key: this.$field('local_board_assignment'),
          sort_field: this.$field('local_board_assignment')+'.raw',
          label: this.$t('labels.board-local'),
          width: 14,
        },
        {
          key: this.$field('workgroup'),
          sort_field: this.$field('workgroup')+'.raw',
          label: this.$t('labels.working-group'),
          width: 14,
        },

        {
          key: 'mobile',
          sort_field: 'mobile.raw',
          label: this.$t('labels.mobile-private'),
          width: 12,
        },
        {
          key: 'email',
          sort_field: 'email.raw',
          label: '',
          width: 5,
        },
      ],

      isLoading: false,
    };
  },
};
</script>
